import {useContext, useEffect} from 'react';
import styles from './Profile.module.css'
import AuthContext from '@/components/LoginForm/auth-context';
import InputField from '@/components/UI/InputField';
import Label from '@/components/UI/Label';
import MainContext from '@/contexts/MainContext';
import {useWindowContext} from '@/hooks';
import {t} from 'i18next';

interface ProfileProps {
}

const Profile = (props: ProfileProps) => {

    const titleCtx = useContext(MainContext);
    const authCtx = useContext(AuthContext)

    const userRoles = authCtx.role?.map(value => value.name.substring(5)).toString();
    const {isMobile} = useWindowContext();

    useEffect(() => {

        if (authCtx.firstName) {
            titleCtx.setTitle(<><Label text={authCtx.firstName + "'s"} className={styles.titleLabelBlue}/><Label
                text={" Profile"} className={styles.titleLabel}/></>)
        }

    }, [authCtx])

    return (
        <div className={styles.container}>
            {isMobile &&
                <Label text={t('profile.title', {name:authCtx.firstName})} className={styles.titleBlue}/>
                }
            <div className={styles.box_container}>
                <div className={styles.box_subContainer}>
                    <div className={isMobile?styles.verticalContainer:styles.horizontalContainer}>

                        <InputField type={'text'} placeholder={authCtx.email} title={''} required={false}
                                    label={"Email"} labelClassName={styles.label} disabled={true}/>
                    </div>
                    <div className={isMobile?styles.verticalContainer:styles.horizontalContainer}>

                        <InputField type={'text'} placeholder={authCtx.company ?? ''} title={''} required={false}
                                    label={"Company"} labelClassName={styles.label} disabled={true}/>
                        <InputField type={'text'} placeholder={authCtx.companyID ?? ''} title={''} required={false}
                                    label={"Company ID"} labelClassName={styles.label} disabled={true}/>

                    </div>
                    <div className={isMobile?styles.verticalContainer:styles.horizontalContainer}>

                        <InputField type={'text'} placeholder={authCtx.firstName} title={''} required={false}
                                    label={"Name"} labelClassName={styles.label} disabled={true}/>
                        <InputField type={'text'} placeholder={authCtx.lastName} title={''} required={false}
                                    label={"Surname"} labelClassName={styles.label} disabled={true}/>
                        <InputField type={'text'} placeholder={userRoles} title={''} required={false} label={"Role"}
                                    labelClassName={styles.label} disabled={true}/>

                    </div>
                </div>


            </div>

        </div>
    )
}

export default Profile
