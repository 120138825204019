import { useTranslation } from 'react-i18next';

import styles from './AcceptConditionsPage.module.css';
import Button from '@Ui/Button';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import AuthContext from '@Components/LoginForm/auth-context';

import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/src/shared/types';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useRemotePost } from '@/hooks';

import cs from '@Assets/pdf/eula_cs.pdf';
import de from '@Assets/pdf/eula_de.pdf';
import en from '@Assets/pdf/eula_en.pdf';
import es from '@Assets/pdf/eula_es.pdf';
import fr from '@Assets/pdf/eula_fr.pdf';
import it from '@Assets/pdf/eula_it.pdf';
import nl from '@Assets/pdf/eula_nl.pdf';
import pt from '@Assets/pdf/eula_pt.pdf';
import { CustomUserExtendedResourceService } from '@/services/openapi';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const maxWidth = 800;
const options = {
    standardFontDataUrl: '/standard_fonts/'
};

const AcceptConditionsPage = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const { forceUpdateUser } = useContext(AuthContext);

    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
    const [numPages, setNumPages] = React.useState<number | null>(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [containerWidth, setContainerWidth] = React.useState<number>();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setContainerWidth(ref.current ? ref.current.offsetWidth : maxWidth);
    }, [ref.current]);

    useEffect(() => {
        if (pageNumber === numPages) {
            setButtonDisabled(false);
        }
    }, [numPages, pageNumber]);

    const acceptConditions = useRemotePost({
        remoteMethod: async () => {
            return CustomUserExtendedResourceService.updateAcceptedConditions();
        }
    });

    const getLanguagePdf = (language: string) => {
        switch (language) {
            case 'cs':
                return cs;
            case 'de':
                return de;
            case 'en':
                return en;
            case 'es':
                return es;
            case 'fr':
                return fr;
            case 'it':
                return it;
            case 'nl':
                return nl;
            case 'pt':
                return pt;
            default:
                return en;
        }
    };
    const pdf = getLanguagePdf(currentLanguage);

    const onDocumentLoadSuccess = ({ numPages }: DocumentCallback) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const handleNextPage = useCallback(() => {
        if (numPages != null && pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
            scrollToRef(ref);
        }
    }, [pageNumber, numPages]);

    const handlePreviousPage = useCallback(() => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            scrollToRef(ref);
        }
    }, [pageNumber]);

    const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref != null && ref.current != null) {
            ref.current.scroll({
                top: 0,
                behavior: 'auto'
            });
        }
    };

    const handleAccept = async () => {
        await acceptConditions.fetch();
        forceUpdateUser();
    };

    return (
        <div className={styles.conditions}>
            <h1>{t('conditions.title')}</h1>
            <div className={styles.containerDocument} ref={ref}>
                {
                    pdf && (<Document file={pdf}
                                      onLoadError={(error) => {
                                          console.error(error);
                                      }}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      options={options}
                    >
                        <Page pageNumber={pageNumber}
                              width={containerWidth ? Math.max(containerWidth, maxWidth) : maxWidth} />
                    </Document>)
                }
            </div>
            <div className={styles.pageNavigation}>
                <Button className={styles.button} disabled={pageNumber <= 1} name={'<'}
                        onClick={handlePreviousPage} />
                <Button className={styles.button} disabled={numPages != null && pageNumber >= numPages} name={'>'}
                        onClick={handleNextPage} />
            </div>
            <div className={styles.buttonContainer}>
                <Button disabled={buttonDisabled} name={t('conditions.accept')} className={styles.button}
                        onClick={handleAccept} />
            </div>
        </div>
    );
};

export default AcceptConditionsPage;