import Img from '@Ui/Img';
import {forwardRef} from "react";

import styles from './Button.module.css';


export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className: string,
    name?: string,
    iconStart?: string,
    iconEnd?: string,
    iconStartClassName?: string,
    iconEndClassName?: string,
    onClick: () => void,
    typeSubmit?: "button" | "submit" | "reset",
    disabled?: boolean
}


const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (

        <>
            <button ref={ref} type={props.typeSubmit ?? 'button'} name={props.name}
                    className={props.className}
                    onClick={props.onClick} disabled={props.disabled}>
                <div className={styles.content}>
                    {props.iconStart &&
                        <Img alt='' className={props.iconStartClassName ? props.iconStartClassName : ""}
                             src={props.iconStart}/>}
                    {props.name}
                    {props.iconEnd &&
                        <Img alt='' className={props.iconEndClassName ? props.iconEndClassName : ""}
                             src={props.iconEnd}/>}
                </div>
            </button>
        </>
    )
});


export default Button
