/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceDTO } from '../models/DeviceDTO';
import type { DeviceModel } from '../models/DeviceModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomDeviceResourceService {

    /**
     * Retrieves a device based on the id specified in input
     * @param id
     * @returns DeviceDTO OK
     * @throws ApiError
     */
    public static getDeviceById(
        id: number,
    ): CancelablePromise<DeviceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Update Device name
     * Update the device name that has the ID specified in input
     * @param id Device Id to filter by
     * @param requestBody
     * @returns DeviceDTO OK
     * @throws ApiError
     */
    public static updateDeviceName(
        id: number,
        requestBody: string,
    ): CancelablePromise<DeviceDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/custom/device/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'text/plain',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves a device by serialNumber
     * @param serialNumber
     * @returns DeviceDTO OK
     * @throws ApiError
     */
    public static getDeviceBySerialNumber(
        serialNumber: string,
    ): CancelablePromise<DeviceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/devices',
            query: {
                'serialNumber': serialNumber,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves all Devices by user id
     * @param typeMachine
     * @returns DeviceDTO OK
     * @throws ApiError
     */
    public static getAllDevicesByUserId(
        typeMachine: string,
    ): CancelablePromise<Array<DeviceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/devices-user',
            query: {
                'typeMachine': typeMachine,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves all Devices by site id
     * @param siteId The ID of the site to filter by
     * @returns DeviceDTO OK
     * @throws ApiError
     */
    public static getAllDevicesBySiteId(
        siteId: number,
    ): CancelablePromise<Array<DeviceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/devices-site/{siteId}',
            path: {
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves device models
     * Return the device models of the devices operating in a certain site
     * @param siteId The ID of the site to filter by
     * @returns DeviceModel OK
     * @throws ApiError
     */
    public static getDeviceModelBySite(
        siteId: number,
    ): CancelablePromise<Array<DeviceModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/deviceModel-site/{siteId}',
            path: {
                'siteId': siteId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * Retrieves devices by multiple filters
     * Return devices filtered by site, routes, device model and multiple device rin
     * @param typeMachine
     * @param siteId
     * @param routeId
     * @param deviceModel
     * @param deviceRins
     * @returns DeviceDTO OK
     * @throws ApiError
     */
    public static getDevicesByMultipleFilters(
        typeMachine: string,
        siteId?: number,
        routeId?: number,
        deviceModel?: string,
        deviceRins?: Array<string>,
    ): CancelablePromise<Array<DeviceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/custom/device-multiple-filters',
            query: {
                'typeMachine': typeMachine,
                'siteId': siteId,
                'routeId': routeId,
                'deviceModel': deviceModel,
                'deviceRins': deviceRins,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}
